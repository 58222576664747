import React from "react"
import SEO from "../components/seo"
import qr from "../images/naihan-qrx2.png"
import gongan from "../images/gongan.png"

import "../components/layout.css"

const Search = () => (
  <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1">
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Artboard"
        transform="translate(-606.000000, -366.000000)"
        fill="#000000"
      >
        <g id="Group-4" transform="translate(586.000000, 258.000000)">
          <g id="Group-2" transform="translate(20.000000, 98.000000)">
            <g id="Search" transform="translate(0.000000, 10.000000)">
              <g id="Group">
                <rect
                  id="Rectangle"
                  opacity="0"
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                ></rect>
                <path
                  d="M16.902,32.778 C8.118,32.778 0.972,25.632 0.972,16.848 C0.972,8.064 8.118,0.9 16.902,0.9 C25.686,0.9 32.832,8.046 32.832,16.83 C32.832,25.614 25.704,32.778 16.902,32.778 Z M16.902,4.248 C9.972,4.248 4.32,9.9 4.32,16.83 C4.32,23.76 9.972,29.412 16.902,29.412 C23.832,29.412 29.484,23.76 29.484,16.83 C29.484,9.9 23.85,4.248 16.902,4.248 Z"
                  id="Shape"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M33.354,35.1 C32.9091551,35.1022854 32.4816947,34.9274152 32.166,34.614 L25.938,28.386 C25.29,27.738 25.29,26.676 25.938,26.01 C26.586,25.344 27.648,25.362 28.314,26.01 L34.542,32.238 C35.19,32.886 35.19,33.948 34.542,34.614 C34.2263053,34.9274152 33.7988449,35.1022854 33.354,35.1 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const IndexPage = () => (
  <>
    <SEO title="首页" />
    <section className="hero is-fullheight">
      <div className="tile">
        <div
          className="tile is-5 is-horizontal"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={qr} alt="qrcode" style={{ width: 302, height: 302 }} />
        </div>
        <div
          className="tile"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#2DA33A",
            minHeight: 300,
          }}
        >
          <div>
            <div style={{ fontSize: 48, color: "white" }}>微信搜一搜</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: 8,
                height: 75,
                width: 335,
              }}
            >
              <div style={{ marginLeft: 22 }}>
                <Search />
              </div>
              <div style={{ fontSize: 40, color: "black", marginLeft: 17 }}>
                奈函眼镜
              </div>
              <div
                style={{
                  width: 4,
                  height: 44,
                  backgroundColor: "#2DA33A",
                  marginLeft: 5,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer>
      <div style={{ textAlign: "center" }}>
        Copyright © 上海河马眼镜有限公司 2018-2021. All Rights Reserved.
      </div>
      <div style={{ textAlign: "center" }}>
        <span>
          <img
            src={gongan}
            style={{
              position: "relative",
              height: "16px",
              top: "4px",
            }}
          />
          京公网安备31011002005651号 &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <a href="http://www.beian.miit.gov.cn">沪ICP备18033169号-2</a>
      </div>
    </footer>
  </>
)

export default IndexPage
